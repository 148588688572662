function handlePropagation(event: React.BaseSyntheticEvent) {
  event.preventDefault();
  event.stopPropagation();
}

const general = {
  handlePropagation,
};

export default general;
