import React from 'react';

import * as inputStyles from './input.module.css';

export type InputProps = {
  type: string;
  id: string;
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Input = (props: InputProps) => {
  return <input className={inputStyles.input} {...props} />;
};
