import React from 'react';

import cn from 'classnames';
import camelCase from 'lodash/camelCase';

import * as textStyles from './text.module.css';

type TextType =
  | 'display'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'body1'
  | 'body2'
  | 'body3'
  | 'body4'
  | 'overline';

export type TextProps = {
  type?: TextType;
  types?: {
    small?: TextType;
    medium?: TextType;
    large?: TextType;
  };
  children: React.ReactNode | React.ReactNode[];
};

export const Text = ({ type, types, children }: TextProps) => {
  const typesClassNames = types
    ? Object.keys(types).map((key) => {
        // @ts-ignore
        const styleKey = `${types[key]}-${key}`;
        const styleKeyCamelCase = camelCase(styleKey);

        return textStyles[styleKeyCamelCase];
      })
    : '';

  const typeClassNames = type
    ? cn(textStyles[`${type}Small`], textStyles[`${type}Medium`], textStyles[`${type}Large`])
    : '';

  const textClassName = cn(textStyles.text, typeClassNames, typesClassNames);

  return <span className={textClassName}>{children}</span>;
};
