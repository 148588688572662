import React from 'react';

import '@fontsource/nunito';

import { ModalProvider } from './src/contexts/ModalContext';
import './src/styles/global.scss';

export const wrapRootElement = ({ element }) => {
  return <ModalProvider>{element}</ModalProvider>;
};
