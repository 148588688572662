// extracted by mini-css-extract-plugin
export var body1Large = "text-module--body1-large--a1adc";
export var body1Medium = "text-module--body1-medium--6244a";
export var body1Small = "text-module--body1-small--0e74a";
export var body2Large = "text-module--body2-large--d853f";
export var body2Medium = "text-module--body2-medium--811ce";
export var body2Small = "text-module--body2-small--43db3";
export var body3Large = "text-module--body3-large--ba811";
export var body3Medium = "text-module--body3-medium--83342";
export var body3Small = "text-module--body3-small--cd2f0";
export var body4Large = "text-module--body4-large--0f663";
export var displayLarge = "text-module--display-large--596e6";
export var displayMedium = "text-module--display-medium--80414";
export var displaySmall = "text-module--display-small--ab1ae";
export var h1Large = "text-module--h1-large--d5118";
export var h1Medium = "text-module--h1-medium--c9755";
export var h1Small = "text-module--h1-small--ce1cb";
export var h2Large = "text-module--h2-large--597c4";
export var h2Medium = "text-module--h2-medium--dc5ee";
export var h2Small = "text-module--h2-small--d1bd1";
export var h3Large = "text-module--h3-large--0c075";
export var h3Medium = "text-module--h3-medium--22ab1";
export var h3Small = "text-module--h3-small--e41ef";
export var h4Large = "text-module--h4-large--e29f7";
export var h4Medium = "text-module--h4-medium--14ddc";
export var h4Small = "text-module--h4-small--02e43";
export var h5Large = "text-module--h5-large--8e04a";
export var h5Medium = "text-module--h5-medium--2c1c3";
export var h5Small = "text-module--h5-small--7034b";
export var overlineLarge = "text-module--overline-large--5b689";
export var overlineMedium = "text-module--overline-medium--d532b";
export var overlineSmall = "text-module--overline-small--1dcc8";
export var text = "text-module--text--0ca80";