import React from 'react';

import { StaticImage } from 'gatsby-plugin-image';

import IconSvgs from 'src/assets/icons';
import { Text } from 'src/components/Text';
import { useModalContext } from 'src/contexts/ModalContext';
import { useNavbarDimensions } from 'src/hooks/useNavbarDimensions';
import translation from 'src/utils/translation';

import * as modalStyles from './modal.module.css';

const heroHeader = '../../images/default/hero-header.png';

export type ModalProps = {
  title: string;
  DescriptionComponent: React.ReactNode | React.ReactNode[];
  hasParrot?: boolean;
};

export const Modal = ({ title, DescriptionComponent, hasParrot }: ModalProps) => {
  const { close } = useModalContext();
  const { height } = useNavbarDimensions();

  return (
    <>
      <div className={modalStyles.overlayContainer} onClick={close} />
      <div
        className={modalStyles.container}
        style={{ top: `calc(50% + ${height / 2}px)`, maxHeight: `calc(100vh - ${height}px)` }}
      >
        <div className={modalStyles.titleContainer}>
          <div
            className={modalStyles.iconContainer}
            style={{
              visibility: 'hidden',
            }}
          >
            <IconSvgs.MenuClose />
          </div>
          <Text type="h5">{title}</Text>
          <div className={modalStyles.iconContainer} onClick={close}>
            <IconSvgs.MenuClose />
          </div>
        </div>
        {hasParrot && (
          <div>
            <StaticImage src={heroHeader} alt={translation.t('modal.image.alt')} width={122} />
          </div>
        )}
        {DescriptionComponent}
      </div>
    </>
  );
};
