import React, { ReactNode, createContext, useCallback, useContext, useState } from 'react';

import { ModalPromo } from 'src/components/ModalPromo';
import { ModalRegistration, ModalRegistrationProps } from 'src/components/ModalRegistration';

type ModalContextProps = {
  modalData: ModalData;
  setModalData: (modalData: ModalData) => void;
  close: () => void;
};

type ModalProviderProps = {
  children: ReactNode | ReactNode[];
};

type Modal = 'promo' | 'register';

type ModalData =
  | {
      type: 'none';
      props: {};
    }
  | {
      type: 'promo';
      props: {};
    }
  | {
      type: 'register';
      props: ModalRegistrationProps;
    };

export const MODALS: Record<Modal, React.FC<any>> = {
  promo: ModalPromo,
  register: ModalRegistration,
};

const ModalContext = createContext<ModalContextProps>({
  modalData: {
    type: 'none',
    props: {},
  },
  setModalData: () => {},
  close: () => {},
});

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [modalData, setModalData] = useState<ModalData>({
    type: 'none',
    props: {},
  });

  const close = useCallback(() => {
    setModalData({
      type: 'none',
      props: {},
    });
  }, []);

  return (
    <ModalContext.Provider
      value={{
        modalData,
        setModalData,
        close,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = (): ModalContextProps => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useModalContext must be used within a ModalProvider');
  }

  return context;
};
