import React from 'react';

import { Link } from 'gatsby';

import cn from 'classnames';
import camelCase from 'lodash/camelCase';
import IconSvgs from 'src/assets/icons';

import * as buttonStyles from './button.module.css';

type ButtonSize = 'small' | 'medium' | 'large';
type ButtonMode =
  | {
      type: 'button';
      onClick: () => void;
    }
  | {
      type: 'anchor';
      href: string;
      shouldOpenInNewTab?: boolean;
    }
  | {
      type: 'link';
      to: string;
    };

function ContainerComponent({
  mode,
  className,
  children,
}: {
  mode: ButtonMode;
  className: string;
  children: React.ReactNode | React.ReactNode[];
}) {
  switch (mode.type) {
    case 'button': {
      return (
        <span className={className} onClick={mode.onClick}>
          {children}
        </span>
      );
    }
    case 'anchor': {
      const target = mode.shouldOpenInNewTab ? '_blank' : undefined;
      const rel = mode.shouldOpenInNewTab ? 'noreferrer' : undefined;

      return (
        <a className={className} href={mode.href} target={target} rel={rel}>
          {children}
        </a>
      );
    }
    case 'link': {
      return (
        <Link className={className} to={mode.to}>
          {children}
        </Link>
      );
    }
    default: {
      return (
        <span className={className} onClick={undefined}>
          {children}
        </span>
      );
    }
  }
}

export type ButtonProps = {
  sizes: {
    xsmall?: ButtonSize;
    small?: ButtonSize;
    medium?: ButtonSize;
    large?: ButtonSize;
  };
  type: 'primary' | 'secondary' | 'tertiary';
  mode: ButtonMode;
  disabled?: boolean;
  icon?: keyof typeof IconSvgs;
  label?: string;
};

export const Button = ({ sizes, type, mode, disabled, icon, label }: ButtonProps) => {
  const sizesClassNames = Object.keys(sizes).map((key) => {
    const prefix = !!label ? 'container' : 'icon-only';

    // @ts-ignore
    const styleKey = `${prefix}-${key}-${sizes[key]}`;
    const styleKeyCamelCase = camelCase(styleKey);

    return buttonStyles[styleKeyCamelCase];
  });

  const iconClassNames = cn(
    Object.keys(sizes).map((key) => {
      // @ts-ignore
      const styleKey = `icon-${key}-${sizes[key]}`;
      const styleKeyCamelCase = camelCase(styleKey);

      return buttonStyles[styleKeyCamelCase];
    }),
  );

  const buttonTypeClassName = disabled ? `${type}Disabled` : type;

  const buttonClassName = cn(
    buttonStyles.container,
    {
      [buttonStyles.containerDisabled]: disabled,
    },
    buttonStyles[buttonTypeClassName],
    sizesClassNames,
  );

  const IconSvg = icon && IconSvgs[icon];

  return (
    <ContainerComponent className={buttonStyles.containerAnchor} mode={mode}>
      <div className={buttonClassName}>
        {!!label ? label : null}
        {icon && <IconSvg className={iconClassNames} />}
      </div>
    </ContainerComponent>
  );
};
