import React from 'react';

import { Button } from 'src/components/Button';
import { Modal } from 'src/components/Modal';
import { Text } from 'src/components/Text';
import appRoutes from 'src/constants/appRoutes';

import * as modalPromoStyles from './modalpromo.module.css';

export const ModalPromo = () => {
  return (
    <Modal
      title="BACK TO SCHOOL AKCIJA"
      DescriptionComponent={
        <>
          <div className={modalPromoStyles.bodyContainer}>
            <div>
              <Text type="body2">30% popusta na Kokolingo softverske pakete uz promo kod:</Text>
            </div>
            <div>
              <Text type="body2">
                <b>SKOLA30</b>
              </Text>
            </div>
            <div>
              <Text type="body2">Požurite! Popust vrijedi do 12. rujna.</Text>
            </div>
          </div>
          <div>
            <Button
              type="primary"
              sizes={{ large: 'medium', medium: 'medium', small: 'medium', xsmall: 'small' }}
              mode={{
                type: 'anchor',
                href: `${appRoutes.login}?redirect=${appRoutes.subscriptionStatus}`,
                shouldOpenInNewTab: true,
              }}
              label="PRIJAVITE SE OVDJE"
            />
          </div>
        </>
      }
      hasParrot
    />
  );
};
