exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-akademija-za-predskolarce-index-tsx": () => import("./../../../src/pages/akademija-za-predskolarce/index.tsx" /* webpackChunkName: "component---src-pages-akademija-za-predskolarce-index-tsx" */),
  "component---src-pages-akademija-za-ustanove-index-tsx": () => import("./../../../src/pages/akademija-za-ustanove/index.tsx" /* webpackChunkName: "component---src-pages-akademija-za-ustanove-index-tsx" */),
  "component---src-pages-cjenik-index-tsx": () => import("./../../../src/pages/cjenik/index.tsx" /* webpackChunkName: "component---src-pages-cjenik-index-tsx" */),
  "component---src-pages-edukativni-kutak-[id]-tsx": () => import("./../../../src/pages/edukativni-kutak/[id].tsx" /* webpackChunkName: "component---src-pages-edukativni-kutak-[id]-tsx" */),
  "component---src-pages-edukativni-kutak-artikulacijski-i-fonoloski-poremecaj-index-tsx": () => import("./../../../src/pages/edukativni-kutak/artikulacijski-i-fonoloski-poremecaj/index.tsx" /* webpackChunkName: "component---src-pages-edukativni-kutak-artikulacijski-i-fonoloski-poremecaj-index-tsx" */),
  "component---src-pages-edukativni-kutak-citanje-i-pisanje-index-tsx": () => import("./../../../src/pages/edukativni-kutak/citanje-i-pisanje/index.tsx" /* webpackChunkName: "component---src-pages-edukativni-kutak-citanje-i-pisanje-index-tsx" */),
  "component---src-pages-edukativni-kutak-dislalija-index-tsx": () => import("./../../../src/pages/edukativni-kutak/dislalija/index.tsx" /* webpackChunkName: "component---src-pages-edukativni-kutak-dislalija-index-tsx" */),
  "component---src-pages-edukativni-kutak-djecja-govorna-apraksija-index-tsx": () => import("./../../../src/pages/edukativni-kutak/djecja-govorna-apraksija/index.tsx" /* webpackChunkName: "component---src-pages-edukativni-kutak-djecja-govorna-apraksija-index-tsx" */),
  "component---src-pages-edukativni-kutak-dvojezicnost-djece-index-tsx": () => import("./../../../src/pages/edukativni-kutak/dvojezicnost-djece/index.tsx" /* webpackChunkName: "component---src-pages-edukativni-kutak-dvojezicnost-djece-index-tsx" */),
  "component---src-pages-edukativni-kutak-igra-kljuc-rasta-i-razvoja-djece-index-tsx": () => import("./../../../src/pages/edukativni-kutak/igra-kljuc-rasta-i-razvoja-djece/index.tsx" /* webpackChunkName: "component---src-pages-edukativni-kutak-igra-kljuc-rasta-i-razvoja-djece-index-tsx" */),
  "component---src-pages-edukativni-kutak-index-tsx": () => import("./../../../src/pages/edukativni-kutak/index.tsx" /* webpackChunkName: "component---src-pages-edukativni-kutak-index-tsx" */),
  "component---src-pages-edukativni-kutak-izgovor-i-terapija-glasova-k-i-g-index-tsx": () => import("./../../../src/pages/edukativni-kutak/izgovor-i-terapija-glasova-k-i-g/index.tsx" /* webpackChunkName: "component---src-pages-edukativni-kutak-izgovor-i-terapija-glasova-k-i-g-index-tsx" */),
  "component---src-pages-edukativni-kutak-kako-je-nastao-kokolingo-za-mobitele-index-tsx": () => import("./../../../src/pages/edukativni-kutak/kako-je-nastao-kokolingo-za-mobitele/index.tsx" /* webpackChunkName: "component---src-pages-edukativni-kutak-kako-je-nastao-kokolingo-za-mobitele-index-tsx" */),
  "component---src-pages-edukativni-kutak-kako-motivirat-djecu-da-ustraju-u-logopedskim-vjezbama-index-tsx": () => import("./../../../src/pages/edukativni-kutak/kako-motivirat-djecu-da-ustraju-u-logopedskim-vjezbama/index.tsx" /* webpackChunkName: "component---src-pages-edukativni-kutak-kako-motivirat-djecu-da-ustraju-u-logopedskim-vjezbama-index-tsx" */),
  "component---src-pages-edukativni-kutak-kako-pronaci-vremena-za-vjezbanje-s-djecom-index-tsx": () => import("./../../../src/pages/edukativni-kutak/kako-pronaci-vremena-za-vjezbanje-s-djecom/index.tsx" /* webpackChunkName: "component---src-pages-edukativni-kutak-kako-pronaci-vremena-za-vjezbanje-s-djecom-index-tsx" */),
  "component---src-pages-edukativni-kutak-kako-uvjezbati-glas-r-index-tsx": () => import("./../../../src/pages/edukativni-kutak/kako-uvjezbati-glas-r/index.tsx" /* webpackChunkName: "component---src-pages-edukativni-kutak-kako-uvjezbati-glas-r-index-tsx" */),
  "component---src-pages-edukativni-kutak-kokolingo-i-glas-s-index-tsx": () => import("./../../../src/pages/edukativni-kutak/kokolingo-i-glas-š/index.tsx" /* webpackChunkName: "component---src-pages-edukativni-kutak-kokolingo-i-glas-s-index-tsx" */),
  "component---src-pages-edukativni-kutak-kokolingo-u-osijeku-index-tsx": () => import("./../../../src/pages/edukativni-kutak/kokolingo-u-osijeku/index.tsx" /* webpackChunkName: "component---src-pages-edukativni-kutak-kokolingo-u-osijeku-index-tsx" */),
  "component---src-pages-edukativni-kutak-logopedska-terapija-index-tsx": () => import("./../../../src/pages/edukativni-kutak/logopedska-terapija/index.tsx" /* webpackChunkName: "component---src-pages-edukativni-kutak-logopedska-terapija-index-tsx" */),
  "component---src-pages-edukativni-kutak-logopedska-terapija-na-daljinu-index-tsx": () => import("./../../../src/pages/edukativni-kutak/logopedska-terapija-na-daljinu/index.tsx" /* webpackChunkName: "component---src-pages-edukativni-kutak-logopedska-terapija-na-daljinu-index-tsx" */),
  "component---src-pages-edukativni-kutak-motoricke-i-slusne-predvjezbe-index-tsx": () => import("./../../../src/pages/edukativni-kutak/motoricke-i-slusne-predvjezbe/index.tsx" /* webpackChunkName: "component---src-pages-edukativni-kutak-motoricke-i-slusne-predvjezbe-index-tsx" */),
  "component---src-pages-edukativni-kutak-mucanje-i-razvojne-netecnosti-u-govoru-index-tsx": () => import("./../../../src/pages/edukativni-kutak/mucanje-i-razvojne-netecnosti-u-govoru/index.tsx" /* webpackChunkName: "component---src-pages-edukativni-kutak-mucanje-i-razvojne-netecnosti-u-govoru-index-tsx" */),
  "component---src-pages-edukativni-kutak-omogucite-svome-djetetu-dobar-start-u-skoli-index-tsx": () => import("./../../../src/pages/edukativni-kutak/omogucite-svome-djetetu-dobar-start-u-skoli/index.tsx" /* webpackChunkName: "component---src-pages-edukativni-kutak-omogucite-svome-djetetu-dobar-start-u-skoli-index-tsx" */),
  "component---src-pages-edukativni-kutak-online-logopedske-vjezbe-index-tsx": () => import("./../../../src/pages/edukativni-kutak/online-logopedske-vjezbe/index.tsx" /* webpackChunkName: "component---src-pages-edukativni-kutak-online-logopedske-vjezbe-index-tsx" */),
  "component---src-pages-edukativni-kutak-ovoga-puta-bez-teorije-samo-praksa-index-tsx": () => import("./../../../src/pages/edukativni-kutak/ovoga-puta-bez-teorije-samo-praksa/index.tsx" /* webpackChunkName: "component---src-pages-edukativni-kutak-ovoga-puta-bez-teorije-samo-praksa-index-tsx" */),
  "component---src-pages-edukativni-kutak-poremecaj-iz-spektra-autizma-index-tsx": () => import("./../../../src/pages/edukativni-kutak/poremecaj-iz-spektra-autizma/index.tsx" /* webpackChunkName: "component---src-pages-edukativni-kutak-poremecaj-iz-spektra-autizma-index-tsx" */),
  "component---src-pages-edukativni-kutak-poticanje-jezicno-govornog-izrazavanja-u-obiteljskom-okruzju-index-tsx": () => import("./../../../src/pages/edukativni-kutak/poticanje-jezicno-govornog-izrazavanja-u-obiteljskom-okruzju/index.tsx" /* webpackChunkName: "component---src-pages-edukativni-kutak-poticanje-jezicno-govornog-izrazavanja-u-obiteljskom-okruzju-index-tsx" */),
  "component---src-pages-edukativni-kutak-poticanje-jezicnog-razumijevanja-i-izrazavanja-index-tsx": () => import("./../../../src/pages/edukativni-kutak/poticanje-jezicnog-razumijevanja-i-izrazavanja/index.tsx" /* webpackChunkName: "component---src-pages-edukativni-kutak-poticanje-jezicnog-razumijevanja-i-izrazavanja-index-tsx" */),
  "component---src-pages-edukativni-kutak-poticanje-pravilnog-izgovora-rijeci-i-glasova-index-tsx": () => import("./../../../src/pages/edukativni-kutak/poticanje-pravilnog-izgovora-rijeci-i-glasova/index.tsx" /* webpackChunkName: "component---src-pages-edukativni-kutak-poticanje-pravilnog-izgovora-rijeci-i-glasova-index-tsx" */),
  "component---src-pages-edukativni-kutak-predvjestine-citanja-i-pisanja-index-tsx": () => import("./../../../src/pages/edukativni-kutak/predvjestine-citanja-i-pisanja/index.tsx" /* webpackChunkName: "component---src-pages-edukativni-kutak-predvjestine-citanja-i-pisanja-index-tsx" */),
  "component---src-pages-edukativni-kutak-rana-intervencija-i-jezicno-govorni-razvoj-index-tsx": () => import("./../../../src/pages/edukativni-kutak/rana-intervencija-i-jezicno-govorni-razvoj/index.tsx" /* webpackChunkName: "component---src-pages-edukativni-kutak-rana-intervencija-i-jezicno-govorni-razvoj-index-tsx" */),
  "component---src-pages-edukativni-kutak-razvoj-glasova-index-tsx": () => import("./../../../src/pages/edukativni-kutak/razvoj-glasova/index.tsx" /* webpackChunkName: "component---src-pages-edukativni-kutak-razvoj-glasova-index-tsx" */),
  "component---src-pages-edukativni-kutak-razvoj-jezika-i-govora-index-tsx": () => import("./../../../src/pages/edukativni-kutak/razvoj-jezika-i-govora/index.tsx" /* webpackChunkName: "component---src-pages-edukativni-kutak-razvoj-jezika-i-govora-index-tsx" */),
  "component---src-pages-edukativni-kutak-spoznajni-razvoj-igre-index-tsx": () => import("./../../../src/pages/edukativni-kutak/spoznajni-razvoj-igre/index.tsx" /* webpackChunkName: "component---src-pages-edukativni-kutak-spoznajni-razvoj-igre-index-tsx" */),
  "component---src-pages-edukativni-kutak-teleterapija-kod-rane-intervencije-index-tsx": () => import("./../../../src/pages/edukativni-kutak/teleterapija-kod-rane-intervencije/index.tsx" /* webpackChunkName: "component---src-pages-edukativni-kutak-teleterapija-kod-rane-intervencije-index-tsx" */),
  "component---src-pages-edukativni-kutak-utjecaj-okoline-na-jezicno-govorni-razvoj-djeteta-index-tsx": () => import("./../../../src/pages/edukativni-kutak/utjecaj-okoline-na-jezicno-govorni-razvoj-djeteta/index.tsx" /* webpackChunkName: "component---src-pages-edukativni-kutak-utjecaj-okoline-na-jezicno-govorni-razvoj-djeteta-index-tsx" */),
  "component---src-pages-edukativni-kutak-vaznost-provodenja-logopedskih-vjezbi-kod-kuce-index-tsx": () => import("./../../../src/pages/edukativni-kutak/vaznost-provodenja-logopedskih-vjezbi-kod-kuce/index.tsx" /* webpackChunkName: "component---src-pages-edukativni-kutak-vaznost-provodenja-logopedskih-vjezbi-kod-kuce-index-tsx" */),
  "component---src-pages-edukativni-kutak-veliki-problem-za-koji-sigurno-niste-culi-index-tsx": () => import("./../../../src/pages/edukativni-kutak/veliki-problem-za-koji-sigurno-niste-culi/index.tsx" /* webpackChunkName: "component---src-pages-edukativni-kutak-veliki-problem-za-koji-sigurno-niste-culi-index-tsx" */),
  "component---src-pages-edukativni-kutak-zasto-logopedi-vole-kokolingo-index-tsx": () => import("./../../../src/pages/edukativni-kutak/zasto-logopedi-vole-kokolingo/index.tsx" /* webpackChunkName: "component---src-pages-edukativni-kutak-zasto-logopedi-vole-kokolingo-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-izjava-o-privatnosti-index-tsx": () => import("./../../../src/pages/izjava-o-privatnosti/index.tsx" /* webpackChunkName: "component---src-pages-izjava-o-privatnosti-index-tsx" */),
  "component---src-pages-kokolingo-ekspert-index-tsx": () => import("./../../../src/pages/kokolingo-ekspert/index.tsx" /* webpackChunkName: "component---src-pages-kokolingo-ekspert-index-tsx" */),
  "component---src-pages-ljetna-akademija-index-tsx": () => import("./../../../src/pages/ljetna-akademija/index.tsx" /* webpackChunkName: "component---src-pages-ljetna-akademija-index-tsx" */),
  "component---src-pages-ucestala-pitanja-index-tsx": () => import("./../../../src/pages/ucestala-pitanja/index.tsx" /* webpackChunkName: "component---src-pages-ucestala-pitanja-index-tsx" */),
  "component---src-pages-uvjeti-kupnje-index-tsx": () => import("./../../../src/pages/uvjeti-kupnje/index.tsx" /* webpackChunkName: "component---src-pages-uvjeti-kupnje-index-tsx" */)
}

