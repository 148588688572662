import externalRoutes from './externalRoutes';

const appRoutes = {
  login: `${externalRoutes.app}/login`,
  register: `${externalRoutes.api}/auth/register/client`,
  registerNoPw: `${externalRoutes.api}/auth/register/client-no-pw`,
  payment: '/settings/subscriptions',
  subscriptionStatus: '/settings/subscription-status',
};

export default appRoutes;
