import React, { useCallback, useState } from 'react';

import axios from 'axios';
import { Button } from 'src/components/Button';
import { ExternalLink } from 'src/components/ExternalLink';
import { Input } from 'src/components/Input';
import { Modal } from 'src/components/Modal';
import { Text } from 'src/components/Text';
import appRoutes from 'src/constants/appRoutes';
import routes from 'src/constants/routes';
import { SubscriptionPackage } from 'src/internalTypes/subscription';
import general from 'src/utils/general';
import translation from 'src/utils/translation';
import validation from 'src/utils/validation';

import * as modalRegistrationStyles from './modalregistration.module.css';

export type ModalRegistrationProps = {
  subscriptionPackage: SubscriptionPackage;
};

export const ModalRegistration = ({ subscriptionPackage }: ModalRegistrationProps) => {
  const [email, setEmail] = useState<string>('');
  const [errorMessageKey, setErrorMessageKey] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleEmailChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }, []);

  const handleClearForm = useCallback(() => {
    setEmail('');
  }, []);

  const handleSubmit = useCallback(
    (event?: React.BaseSyntheticEvent) => {
      event && general.handlePropagation(event);

      if (!validation.validateEmail(email)) {
        setErrorMessageKey('registerError.email');
        return;
      }

      setLoading(true);

      const fullName = email.substring(0, email.lastIndexOf('@'));
      const studentFullName = `${fullName} - dijete`;

      axios
        .post(appRoutes.registerNoPw, {
          studentFullName,
          fullName,
          email,
          gdpr: true,
        })
        .then((response) => {
          if (response.status === 201) {
            window.location.href = `${appRoutes.login}?token=${response.data.token}&redirect=${appRoutes.payment}?subscriptionKey=${subscriptionPackage}`;
          }
        })
        .catch((error) => {
          if (error.response?.data?.message === 'error.feedback.emailInUse') {
            window.location.href = `${appRoutes.login}?redirect=${appRoutes.payment}?subscriptionKey=${subscriptionPackage}`;
          } else if (error.response?.data?.message === 'error.feedback.subscriptionIsActive') {
            window.location.href = `${appRoutes.login}?redirect=${appRoutes.subscriptionStatus}`;
          } else if (!error.response) {
            setErrorMessageKey('registerError.server');
          } else {
            setErrorMessageKey('registerError.unknown');
          }

          handleClearForm();
        })
        .finally(() => setLoading(false));
    },
    [email, handleClearForm, subscriptionPackage],
  );

  const subscriptionPackageToDisplay = subscriptionPackage.toUpperCase();

  return (
    <Modal
      title={`Odabrali ste paket: ${subscriptionPackageToDisplay}`}
      DescriptionComponent={
        <div className={modalRegistrationStyles.bodyContainer}>
          <div>
            <Text type="h5">Molimo unesite vašu e-mail adresu za Kokolingo aplikaciju</Text>
          </div>
          <form onSubmit={handleSubmit}>
            <Text type="body1">
              <Input
                id="email"
                type="email"
                placeholder={translation.t('input.email')}
                value={email}
                onChange={handleEmailChange}
              />
              {!!errorMessageKey && (
                <span className={modalRegistrationStyles.errorText}>
                  <b>{translation.t(errorMessageKey)}</b>
                </span>
              )}
            </Text>
          </form>
          <div>
            {loading ? (
              <Text type="body1">{translation.t('register.loading')}</Text>
            ) : (
              <Button
                type="primary"
                sizes={{ large: 'small', medium: 'small', small: 'small', xsmall: 'small' }}
                mode={{ type: 'button', onClick: handleSubmit }}
                label={translation.t('input.button')}
              />
            )}
          </div>
          <div>
            <Text type="body2">
              Ovime prihvaćate{' '}
              <ExternalLink href={routes.termsOfService}>
                <b>Uvjete korištenja</b>
              </ExternalLink>{' '}
              i{' '}
              <ExternalLink href={routes.privacyPolicy}>
                <b>Izjavu o privatnosti</b>
              </ExternalLink>
            </Text>
          </div>
        </div>
      }
    />
  );
};
