import ArrowDown from './arrow-down.svg';
import ArrowForward from './arrow-forward.svg';
import ArrowLeft from './arrow-left.svg';
import ArrowRight from './arrow-right.svg';
import ArrowUp from './arrow-up.svg';
import Banknote from './banknote.svg';
import Buildings from './buildings.svg';
import Calendar from './calendar.svg';
import ChecklistCheckmark from './checklist-checkmark.svg';
import ChecklistCross from './checklist-cross.svg';
import Gameboy from './gameboy.svg';
import GraphUp from './graph-up.svg';
import Graph from './graph.svg';
import HandHeart from './hand-heart.svg';
import Home from './home.svg';
import MenuButton from './menu-button.svg';
import MenuClose from './menu-close.svg';
import Microphone from './microphone.svg';
import Minus from './minus.svg';
import PlayBold from './play-bold.svg';
import Plus from './plus.svg';
import SocialFacebook from './social-facebook.svg';
import SocialInstagram from './social-instagram.svg';
import SocialYoutube from './social-youtube.svg';

const icons = {
  ArrowDown,
  ArrowForward,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  Banknote,
  Buildings,
  Calendar,
  ChecklistCheckmark,
  ChecklistCross,
  Gameboy,
  GraphUp,
  Graph,
  HandHeart,
  Home,
  MenuButton,
  MenuClose,
  Microphone,
  Minus,
  PlayBold,
  Plus,
  SocialFacebook,
  SocialInstagram,
  SocialYoutube,
};

export default icons;
