import { useEffect, useMemo, useState } from 'react';

export const useNavbarDimensions = () => {
  const [height, setHeight] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);

  useEffect(() => {
    const navbarPlaceholderObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setHeight(entry.contentRect.height);
        setWidth(entry.contentRect.width);
      });
    });

    const navbarPlaceholder = document.querySelector('#navbarplaceholder');

    navbarPlaceholder && navbarPlaceholderObserver.observe(navbarPlaceholder);

    return () => {
      navbarPlaceholderObserver.disconnect();
    };
  }, []);

  return useMemo(() => ({ height, width }), [height, width]);
};
